import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { IoHeart } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { IoCalendarSharp } from "react-icons/io5";
import MainDashboard from "views/admin/default";
import vendus from "views/admin/prodPlusVendus";
import Properimes from "medicProperimes";
//import Profile from "views/admin/profile";
import Favoris from "views/admin/favourite";
import Masked from "views/admin/masked";
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Produits les plus vendus",
    layout: "/admin",
    path: "/produits-vendus",
    icon: <Icon as={IoMdCart} width="20px" height="20px" color="inherit" />,
    component: vendus,
    secondary: true,
  },
  {
    name: "Produits favoris",
    layout: "/admin",
    icon: <Icon as={IoHeart} width="20px" height="20px" color="inherit" />,
    path: "/produits-favoris",
    component: Favoris,
  },
  {
    name: "Produits masqués",
    layout: "/admin",
    icon: <Icon as={FaEyeSlash} width="20px" height="20px" color="inherit" />,
    path: "/produits-masques",
    component: Masked,
  },
  {
    name: "Produits propérimés",
    layout: "/admin",
    path: "/produits-properimes",
    icon: (
      <Icon as={IoCalendarSharp} width="20px" height="20px" color="inherit" />
    ),
    component: Properimes,
    secondary: true,
  },

  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    layout: "/auth",
    path: "/sign-in",
    component: SignInCentered,
  },
];

export default routes;
