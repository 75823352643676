import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import Profils from "views/admin/default/components/Profils";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdBarChart } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { MdOutlineSell } from "react-icons/md";

import Patients from "views/admin/default/components/Patients";

import Utilisations from "views/admin/default/components/Utilisations";
import PlaintesConsultees from "views/admin/default/components/PlaintesConsultees";
import { CiCalendar } from "react-icons/ci";

export default function UserReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [totalProp, setTotalProp] = useState(0);
  const [products, setProducts] = useState(0);
  const [equivalent, setEquivalent] = useState(0);
  const [totalVentes, setTotalVentes] = useState(0);
  const [venteProperime, setVenteProperime] = useState(0);
  const [totalPropVend, setTotalPropVend] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      const response2 = axios
        .get("https://mvp.saydalid.com/api/properemption_medicament", {
          headers: { "x-access-token": token },
        })
        .catch((error) => {
          console.log("Error fetching properemption_medicament:", error);
          return { data: [] };
        });

      const response3 = axios
        .get("https://mvp.saydalid.com/api/cart", {
          headers: { "x-access-token": token },
        })
        .catch((error) => {
          console.log("Error fetching cart:", error);
          return { status: 404, data: [] };
        });

      try {
        const [res2, res3] = await Promise.all([response2, response3]);
        //console.log("res2", res2.data);
        //console.log(res3.data);

        let filtered = res2.data.filter(
          (elem) => !elem.masked && elem.qte_stock > 0
        );
        //console.log("filtered", filtered);

        let price = 0;
        let total = 0;
        filtered.forEach((item) => {
          price += item.prix * item.qte_stock;
          total += item.qte_stock;
        });
        setProducts(total);
        setEquivalent(price.toFixed(3));

        let counter = [];
        filtered.forEach((item) => {
          if (!counter.includes(item.specialite)) {
            counter.push(item.specialite);
          }
        });
        //console.log(counter);
        setTotalProp(counter.length);

        function sixMonths(date1, date2) {
          const d1 = date1 instanceof Date ? date1 : new Date(date1);
          const d2 = date2 instanceof Date ? date2 : new Date(date2);

          const monthDiff =
            (d2.getFullYear() - d1.getFullYear()) * 12 +
            (d2.getMonth() - d1.getMonth());

          return Math.abs(monthDiff) <= 6;
        }
        if (res3.status !== 404) {
          let ventes = 0;
          let venteProp = 0;
          let propVend = 0;

          const today = new Date();
          //console.log("res3", res3.data);

          res3.data.forEach((item) => {
            ventes += item.items.length;

            item.items.forEach((elem) => {
              if (elem.medicamentDetails) {
                //console.log("test", elem);
                const peremptionDate = new Date(
                  elem.medicamentDetails.date_peremption
                );
                if (sixMonths(today, peremptionDate)) {
                  venteProp++;
                  propVend += elem.quantity * elem.medicamentDetails.prix;
                }
              }
            });
          });
          setTotalVentes(ventes);
          setVenteProperime(venteProp);
          setTotalPropVend(propVend);
          //console.log(totalPropVend);
        }

        //console.log("cart", res3.data);
      } catch (error) {
        console.log("Error during fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              //bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdOutlineSell} color="blue" />}
            />
          }
          name="Nombre total de produits vendus"
          value={totalVentes}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={CiCalendar} color={brandColor} />
              }
            />
          }
          name="Produits propérimés vendus"
          value={venteProperime}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={GrMoney} color={brandColor} />}
            />
          }
          name="Equivalent pro-périmés vendus en TND"
          value={Math.round((totalPropVend + Number.EPSILON) * 100) / 100}
        />
        <MiniStatistics
          name="Produits pro-périmés en stock"
          value={products}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={CiCalendar} color={brandColor} />
              }
            />
          }
          name="Nombre de spécialités propérimées"
          value={totalProp}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={GrMoney} color={brandColor} />}
            />
          }
          name="Equivalent pro-périmés en stock en TND"
          value={equivalent}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <Utilisations />
        <PlaintesConsultees />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        <SimpleGrid columns={{ base: 3, md: 2, xl: 2 }} gap="180px">
          {/* <DailyTraffic /> */}
          <Patients />
          <Profils />
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
}
