import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";

export const pieChartOptions = {
  labels: ["Homme", "Femme", "Enfant"],
  colors: ["#0066FF", "#33FFFF", "#678BF4"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#0066FF", "#33FFFF", "#678BF4"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};
export default function Conversion(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let Homme = 0;
      let Femme = 0;
      let enf = 0;

      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      try {
        const response = await axios.get(
          "https://mvp.saydalid.com/api/track/action/AFFICHER_TRAITEMENT",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        //console.log(response.data);
        let total = response.data.length;
        response.data.forEach((item) => {
          if (item.data.patientProfile.gender === "F") {
            Femme++;
          }
          if (item.data.patientProfile.gender === "H") {
            Homme++;
          }
          if (item.data.patientProfile.gender === "E") {
            enf++;
          }
        });
        const femPercentage = (Femme / total) * 100;
        const HommePercentage = (Homme / total) * 100;
        const enfPercentage = (enf / total) * 100;

        setChartData([HommePercentage, femPercentage, enfPercentage]);
      } catch (error) {
        console.log("Error making the request:", error);
        setChartData([0, 0, 0]);
      }
    };

    fetchData();
  }, []);

  if (chartData === null) {
    return <div>Loading...</div>;
  }

  return (
    <Card p="20px" align="center" direction="column" w="180%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Répartition des patients
        </Text>
      </Flex>

      <PieChart
        h="100%"
        w="100%"
        chartData={chartData}
        chartOptions={{
          ...pieChartOptions,
          tooltip: {
            ...pieChartOptions.tooltip,
            y: {
              formatter: (value) => `${value.toFixed(1)}%`,
            },
          },
        }}
      />

      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        {["Homme", "Femme", "Enfant"].map((label, index) => (
          <React.Fragment key={index}>
            <Flex direction="column" py="5px">
              <Flex align="center">
                <Box
                  h="8px"
                  w="8px"
                  bg={pieChartOptions.colors[index]}
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xs"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  {label}
                </Text>
              </Flex>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {chartData[index].toFixed(1)}%
              </Text>
            </Flex>
            {index < 3 && (
              <VSeparator mx={{ base: "60px", xl: "30px", "2xl": "60px" }} />
            )}
          </React.Fragment>
        ))}
      </Card>
    </Card>
  );
}
