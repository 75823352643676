import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import BarChart from "components/charts/BarChart";
import React, { useState } from "react";

import { MdBarChart } from "react-icons/md";
import axios from "axios";
import { useEffect } from "react";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;
  const [top10, setTop10] = useState([]);
  const [value, setValue] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      try {
        const response = await axios.get(
          "https://mvp.saydalid.com/api/track/action/AFFICHER_TRAITEMENT",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        const selectedPathologies = response.data.flatMap(
          (item) => item.data.selectedPathologies
        );
        const counter = selectedPathologies.reduce((accumulator, currVal) => {
          const pathology = currVal.pathologie;
          if (Object.keys(accumulator).some((elem) => elem === pathology)) {
            accumulator[pathology] += 1;
          } else {
            accumulator[pathology] = 1;
          }
          return accumulator;
        }, {});
        //console.log("counter", counter);
        const sortedCounterArray = Object.entries(counter).sort(
          (a, b) => b[1] - a[1]
        );
        //console.log("counterarray", sortedCounterArray);

        const top10Pathologies = sortedCounterArray
          .slice(0, 10)
          .map((item) => item[0]);

        setValue(sortedCounterArray.slice(0, 10).map((item) => item[1]));

        setTop10(top10Pathologies);
      } catch (error) {
        console.log("Error making the request:", error);
      }
    };

    fetchData();
  }, []);
  const barChartDataConsumption = [
    {
      name: "Nb de consultations",
      data: value,
    },
  ];

  const barChartOptionsConsumption = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
    },
    xaxis: {
      categories: top10,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: false,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
    },
    legend: {
      show: false,
    },
    colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "20px",
      },
    },
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Plaintes les plus consultées
        </Text>
        <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
      </Flex>

      <Box h="250px" mt="auto">
        {top10.length > 0 && value.length > 0 && (
          <BarChart
            chartData={barChartDataConsumption}
            chartOptions={barChartOptionsConsumption}
          />
        )}
      </Box>
    </Card>
  );
}
