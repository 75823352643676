//import React, { useEffect, useState } from "react";
//import axios from "axios";
import {
  Flex,
  // Checkbox
} from "@chakra-ui/react";

//import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
import Card from "components/card/Card.js";
//import defaultImage from "assets/img/meds.png";

export default function Favoris() {
  // const [items, setItems] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const token =
  //       localStorage.getItem("token") || sessionStorage.getItem("token");

  //     try {
  //       const response = await axios.get(
  //         "https://mvp.saydalid.com/api/masked",
  //         {
  //           headers: {
  //             "x-access-token": token,
  //           },
  //         }
  //       );
  //       setItems(response.data);
  //     } catch (error) {
  //       console.log("Error making the request:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const handleMaskedChange = async (item) => {
  //   const token =
  //     localStorage.getItem("token") || sessionStorage.getItem("token");

  //   try {
  //     const response = await axios.post(
  //       "https://mvp.saydalid.com/api/update_medicine",
  //       {
  //         medicineId: item._id,
  //         masked: false, // set the attribute as it is set in the backend
  //       },
  //       {
  //         headers: {
  //           "x-access-token": token,
  //         },
  //       }
  //     );

  //     //console.log("API Response:", response.data);

  //     if (response.status === 200) {
  //       setItems((prevItems) =>
  //         prevItems
  //           .map((currentItem) =>
  //             currentItem._id === item._id
  //               ? {
  //                   ...currentItem,
  //                   masked: false, // set the attribute as it is set in the backend
  //                 }
  //               : currentItem
  //           )
  //           .filter((currentItem) => currentItem.masked)
  //       );
  //     } else {
  //       console.log("Failed to update the medicine's masked status.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating masked status:", error);
  //   }
  // };

  return (
    <Flex flexDirection="column" py="70px">
      <Card p="0px">
        <div>
          {/* {items.length > 0 ? (
            items.map((item, index) => (
              <Flex key={index} alignItems="center" mb="10px">
                <Checkbox
                  defaultChecked
                  isChecked={item.masked}
                  onChange={() => handleMaskedChange(item)}
                  // mr="-10"
                  p="10px"
                />
                <HistoryItem
                  name={item.specialite}
                  image={item.photo || defaultImage}
                  price={item.prix}
                  forme={item.forme}
                  text="TND"
                />
              </Flex>
            ))
          ) : ( */}
          <p>No items to display</p>
          {/* )} */}
        </div>
      </Card>
    </Flex>
  );
}
