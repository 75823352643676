import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex } from "@chakra-ui/react";

import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
import defaultImage from "assets/img/meds.png";
export default function Properimes() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      try {
        const response = await axios.get(
          "https://mvp.saydalid.com/api/properemption_medicament",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        // console.log("properimes", response.data);
        let acc = {};

        response.data.forEach((item) => {
          const itemName = item?.specialite;
          const itemImage = item.photo;
          const itemPrice = item?.qte_stock; //qte du produit en stock
          const itemForme = item?.forme;

          if (!Object.keys(acc).includes(itemName)) {
            acc[itemName] = {
              image: itemImage,
              price: itemPrice,
              forme: itemForme,
            };
          }
        });
        //console.log("acc", acc);
        const sortedCounts = Object.entries(acc).sort(
          (a, b) => b[1].price - a[1].price // tri selon la qte en stock la plus elevee
        );

        const prodProperimes = sortedCounts.map(([name, details]) => ({
          name: name,
          image: details.image,
          price: details.price, // represents the product's quantity in stock
          forme: details.forme,
        }));
        //console.log(prodProperimes);
        setProducts(prodProperimes);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setProducts([]);
        } else {
          console.log("Error making the request:", error);
          setProducts([]);
        }
      }
    };

    fetchData();
  }, []);
  return (
    <Flex
      flexDirection="column"
      //gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
      py="70px"
    >
      <div>
        {products.length > 0 ? (
          products.map((item, index) => (
            <HistoryItem
              key={index}
              name={item.name}
              image={item.image || defaultImage}
              price={item.price}
              forme={item.forme}
            />
          ))
        ) : (
          <p>No items to display</p>
        )}
      </div>
    </Flex>
  );
}
